import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import NotFoundPageComponent from '../components/NotFoundComponent';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundPageComponent />
  </Layout>
);

export default NotFoundPage;
